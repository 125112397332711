import styled from 'styled-components';
import Colours from '../../constants/colours';
import Typography from '../../constants/typography';
// @ts-ignore
import BlackSpinner from '../../images/button-black-spinner.gif';

export const Wrapper = styled.div`
  transform: translateY(0.1em);
  user-select: none;
`;

export const BlackOutlineButton = styled.button`
  font-size: ${Typography.button.fontSize};
  padding: 8px 24px;
  background-color: transparent;
  border: 1px solid ${Colours.black};
  outline: none;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  width: max-content;

  &:hover {
    background-color: ${Colours.black};
    color: ${Colours.white};
  }

  &:active {
    background-color: ${Colours.black};
    border-color: ${Colours.black};
  }
`;

export const WhiteOutlineButton = styled(BlackOutlineButton)`
  border: 1px solid ${Colours.white};
  color: ${Colours.white};

  &:hover {
    background-color: ${Colours.white};
    color: ${Colours.black};
  }
`;

export const GreenButton = styled(BlackOutlineButton)`
  background-color: ${Colours.green};
  color: ${Colours.white};
  border: 1px solid ${Colours.green};

  &:hover {
    border: 1px solid ${Colours.black};
  }

  &.submitting {
    background-color: ${Colours.black};
    border: 1px solid ${Colours.black};
    cursor: default;
  }

  &.submitting > div {
    background-repeat: no-repeat;
    background-position: center 0px;
    background-image: url(${BlackSpinner});
    color: transparent;
  }

  &:disabled {
    background-color: ${Colours.grey[300]};
    border: 1px solid ${Colours.grey[300]};
    color: ${Colours.grey[500]};
    cursor: not-allowed;
  }

  &.submitting:disabled {
    border: 1px solid ${Colours.black};
    background-color: ${Colours.black};
    cursor: not-allowed;
  }
`;

export const WhiteButton = styled(GreenButton)`
  border: 1px solid ${Colours.white};
  color: ${Colours.black};
  background-color: ${Colours.white};

  &:hover {
    background-color: ${Colours.black};
    color: ${Colours.white};
  }
`;

export const PurpleButton = styled(GreenButton)`
  border: 1px solid ${Colours.subscriberPurple};
  color: ${Colours.white};
  background-color: ${Colours.subscriberPurple};

  &:hover {
    background-color: ${Colours.black};
    color: ${Colours.white};
  }
`;

export const BlackOutlineWhiteFill = styled(BlackOutlineButton)`
  background-color: ${Colours.white};
`;
