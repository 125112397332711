import React, {
  FunctionComponent,
  MouseEvent,
  ReactElement,
  ReactNode,
} from 'react';
import {CSSObject} from 'styled-components';
import {
  Wrapper,
  BlackOutlineButton,
  BlackOutlineWhiteFill,
  WhiteOutlineButton,
  WhiteButton,
  PurpleButton,
  GreenButton,
} from './Button.styles';

export enum ButtonVariant {
  GREEN,
  BLACK,
  WHITE,
  PURPLE,
  BLACK_OUTLINE,
  WHITE_OUTLINE,
  BLACK_OUTLINE_WHITE_FILL,
}

export interface ButtonProps {
  variant?: ButtonVariant;
  children?: ReactNode;
  onClick?(event: MouseEvent): void;
  title?: string;
  showSpinner?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
  id?: string;
}

export const Button: FunctionComponent<ButtonProps> = (props) => {
  switch (props.variant) {
    case ButtonVariant.BLACK_OUTLINE:
      return (
        <BlackOutlineButton {...props}>
          <Wrapper>{props.children}</Wrapper>
        </BlackOutlineButton>
      );
    case ButtonVariant.BLACK_OUTLINE_WHITE_FILL:
      return (
        <BlackOutlineWhiteFill {...props}>
          <Wrapper>{props.children}</Wrapper>
        </BlackOutlineWhiteFill>
      );
    case ButtonVariant.WHITE_OUTLINE:
      return (
        <WhiteOutlineButton {...props}>
          <Wrapper>{props.children}</Wrapper>
        </WhiteOutlineButton>
      );
    case ButtonVariant.WHITE:
      return (
        <WhiteButton {...props}>
          <Wrapper>{props.children}</Wrapper>
        </WhiteButton>
      );
    case ButtonVariant.GREEN:
      return (
        <GreenButton
          {...props}
          className={props.showSpinner ? 'submitting' : ''}
          disabled={props.showSpinner || props.disabled}
        >
          <Wrapper>{props.children}</Wrapper>
        </GreenButton>
      );
      case ButtonVariant.PURPLE:
        return (
          <PurpleButton
            {...props}
            className={props.showSpinner ? 'submitting' : ''}
            disabled={props.showSpinner || props.disabled}
          >
            <Wrapper>{props.children}</Wrapper>
          </PurpleButton>
        );
    default:
      return (
        <GreenButton
          {...props}
          className={props.showSpinner ? 'submitting' : ''}
          disabled={props.showSpinner || props.disabled}
        >
          <Wrapper>{props.children}</Wrapper>
        </GreenButton>
      );
  }
};
