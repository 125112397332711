import {FluidObject} from 'gatsby-image';

interface PageDataQuery {
  en: {
    edges: any[];
  };
}

interface BuildEnvQuery {
  site: {
    siteMetadata: {
      buildEnv: string;
    };
  };
}

export const getPageData = (data: PageDataQuery) =>
  data.en.edges[0].node || false;

export const getBuildEnvFromData = (data: BuildEnvQuery) =>
  data.site.siteMetadata.buildEnv || false;

interface ApiUrlQuery {
  site: {
    siteMetadata: {
      apiUrl: string;
    };
  };
}

export const getApiUrlFromData = (data: ApiUrlQuery) =>
  data.site.siteMetadata.apiUrl;

interface RecurlyPublicKeyQuery {
  site: {
    siteMetadata: {
      recurlyPublicKey: string;
    };
  };
}

export const getRecurlyPublicKeyFromData = (data: RecurlyPublicKeyQuery) =>
  data.site.siteMetadata.recurlyPublicKey;

interface PremiumPlanIdQuery {
  planData: {
    edges: Array<{node: {premiumId: string}}>;
  };
}

export const getStudioPremiumPlanId = (data: PremiumPlanIdQuery) =>
  data.planData.edges[0].node.premiumId || '';

export const getImageFromData = (data: any, imageName: string): FluidObject =>
  getLocalImageFromFragment(data[imageName]);

export const getLocalImageFromFragment = (fragment: any): FluidObject =>
  fragment?.localFile?.childImageSharp?.fluid;

export {getQueryParameter} from './url';
