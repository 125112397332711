import {BUILD_ENV_PROD} from '../constants/apps';

interface CustomEvent {
  key: string;
  segmentation?: object;
}

interface FeedbackEvent {
  widget_id: string;
  comment: string;
  rating: number;
  email: string;
  contactMe: boolean;
}

const cookieConsentGiven = () => {
  return (window as any)._iub?.cs?.api.isConsentGiven() || false;
};

export const sendAnalyticsEvent = (key: string, segmentation?: object) => {
  sendEvent('add_event', {key, segmentation} as CustomEvent);
};

export const sendFeedbackEvent = (data: object) => {
  sendEvent('report_feedback', {
    widget_id: '1234567890',
    rating: 3,
    email: '',
    contactMe: false,
    ...data,
  } as FeedbackEvent);
};

export const sendStudioDownloadPromptEvent = (os: string) => {
  sendAnalyticsEvent('studio-download', {os});
};

export const sendStudioDownloadEvent = (os: string) => {
  sendAnalyticsEvent('studio-download-prompt', {os});
};

const sendEvent = (event: string, data: CustomEvent | FeedbackEvent) => {
  if (cookieConsentGiven()) {
    const interval = setInterval(() => {
      if (window.hasOwnProperty('Countly')) {
        (window as any).Countly.q.push([event, data]);
        clearInterval(interval);
      }
    }, 200);
  }
};

export const shouldInjectCountly = () => {
  return typeof window !== 'undefined' && !(window as any).Cypress;
};

export const getCountlySrc = (env: string | false) => {
  if (!env) {
    return null;
  }

  return env === BUILD_ENV_PROD ? '/countly.js' : '/countly-dev.js';
};
